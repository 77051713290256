import type { QueryKey } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { ListResponse } from 'lib/api';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { Rule } from '../types';
import useFilterQueryValues from '../hooks/useFilterQueryValues';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { omit } from 'lodash-es';
import type { RuleStatusValue } from '../../types';
import { getRulesListOptions, GOOGLE_RULES_LIST_KEY } from './getRulesList';

interface ChangeStatusResponse {
  data: unknown;
}

interface ChangeStatusPayload {
  ruleId: number;
  status: RuleStatusValue;
}

export const changeRuleStatus = async ({ status, ruleId }: ChangeStatusPayload): Promise<ChangeStatusResponse> => {
  const res = await apiClient.put<{ data: ChangeStatusResponse }>(`/ga/rules/${ruleId}/status`, { status });
  return res.data.data;
};

interface UseChangeRuleStatusOptions {
  config?: MutationConfig<typeof changeRuleStatus>;
}

export const useChangeRuleStatus = ({ config }: UseChangeRuleStatusOptions = {}) => {
  const queryClient = useQueryClient();
  const params = useFilterQueryValues();
  const queryKey = getRulesListOptions(omit(params, 'paginationRelatedParams')).queryKey as QueryKey;

  const { onShowAlert, onShowErrorAlert } = useEnqueueSnackbar({ delay: 5000 });

  return useMutation({
    ...config,
    onMutate: async (payload) => {
      await queryClient.cancelQueries({ queryKey });

      const previousRules = queryClient.getQueryData(queryKey);

      await queryClient.setQueryData(queryKey, (old: ListResponse<Rule>) => {
        if (!old) return undefined;

        const updatedIndex = old.data.findIndex((r) => r.id === payload.ruleId);
        const cacheCopy = { ...old };

        cacheCopy.data.splice(updatedIndex, 1, {
          ...old.data[updatedIndex],
          status: payload.status,
        });

        return cacheCopy;
      });

      return { previousRules };
    },
    onSuccess: async (...args) => {
      onShowAlert(`Rule ${args[1].status}`);

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [GOOGLE_RULES_LIST_KEY],
        }),
        queryClient.invalidateQueries({
          queryKey: ['ga/channels'],
        }),
      ]);

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    onError: async (err, payload, context) => {
      await queryClient.setQueryData(queryKey, (context as { previousRules: ListResponse<Rule> })?.previousRules);
      onShowErrorAlert('Error');
      console.error(err);
    },
    mutationFn: changeRuleStatus,
  });
};
